.img-favorites-empty {
  display: grid;
  img {
    width: 80%;
    margin: auto !important;
  }
}

.container-redirect-favorites {
  margin: 2vh auto !important;
  width: 100%;
  text-align: center;
  .btn-redirect-favorites-bash {
    width: 100%;
  }
}

.text-empty-favorites-bash {
  color: #000000;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

.container-carousel-favorites {
  margin-bottom: 30vh;
}

.change-password-container {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.change-password-section {
  margin: 2rem;
  min-width: 400px;
}

@media screen and (max-width: 1050px) {
  .container-carousel-favorites {
    margin-bottom: 10vh;
  }
}
