.curtain-detail-event {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 1000;
  overflow-y: auto;
}

.container-detail-event-card {
  width: 80%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-color: #FFFFFF;
  border-radius: 13px;
  position: relative;
  
  .container-img-detail-event-card {
    display: flex;
    height: 400px;
    img {
      width: 100%;
      height: auto;
      border-radius: 13px;
      object-fit: cover;
    }
  }
}

.container-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #858585;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
  }
}

.container-info-detail-event {
  padding: 10px 15px;
  transform: translateY(-50%);
  width: 95%;
  margin: auto !important;
  background-color: #FFFFFF;
  border-radius: 16px !important;
  box-shadow: 0px 3px 20px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
}

.container-secction-info-detail-event {
  margin-top: 3vh !important;
}
.title-detail-event {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}
.container-basic-info-event {
  display: flex;

  .qualification-event-detail {
    align-items: center;
    display: flex;
    color: #292929;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
}

.title-secction-detail-event {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}
.description-secction-detail-event {
  color: #292929;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
}
.btn-register-event {
  color: #FFFFFF;
}
.back-btn-phones {
  cursor: pointer;
  display: none;
}
.qualification-comments-desktop{
  display: flex;
}
.qualification-comments-mobile{
  display: none;
}

.title-event-detail-event {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}
.description-event-detail-event {
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 20px;
}
.container-detail-event {
  width: 90%;
  margin: auto;
  margin-top: -50px;
}

.container-secction {
  margin-top: 50px;
  width: 90%;

  textarea {
    width: 100%;
    border-radius: 8px;
    border-color: #292929;
    margin-top: 10px;
  }
}
.description-container-event-detail {
  margin-top: 20px;
}

.container-btn-form-message {
  position: relative;
  .btn-form-message {
    position: absolute;
    right: 0;
  }
}

.container-social-icons{
  display: flex;
  margin-top: 10px;

  svg {
    margin-right: 10px;
  }
}

.btn-register-phone-event{
  display: none;
}

.container-footer-register-event{
  display: none;
}

.container-title-another-events{
  margin-bottom: 30px;
}

.content-items-necesary{
  width: 270px;
}

.contetnt-item-description{
  border: 1px solid #979797;
  border-radius: 21px;
  padding: 10px;
  text-align: left;
  margin-top: 15px;

  img{
    width: 200px;
    height: auto !important;
    margin-bottom: 15px;
    text-align: center;
    margin-left: 20px;
  }

  .description-item {
    color: #000000;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .price-item{
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
  }
  .url-item{
    color: #000000;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
  }
}

.grid-necesary-items{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.actions-container {
  display: flex;
  position: absolute;
  top: -15px;
  right: 15px;

  .rounded-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;

    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 02.5rem);
    border-radius: 2rem;
    width: 2.5rem;
    height: 2.5rem;

    cursor: pointer;

    &.favorite {
      .fill {
        color: red;
      }
    }

    .icon {
      width: 1.7rem;
      height: 1.7rem;
      color: #979797;
    }
  }

}

@media screen and (max-width:900px){
  .back-btn-phones {
    display: flex;
    padding: 10px 15px;
    svg {
      width: 35px;
      height: 35px;
    }
  }
  .container-close-btn {
    display: none;
  }
  .container-detail-event-card {
    width: 100%;
    margin: 0;
    border-radius: 0;
    
    .container-img-detail-event-card{
      img {
        border-radius: 0;
      }
    }
  }
  .container-info-detail-event {
    padding: 10px 15px;
    transform: translateY(0);
    width: 100%;
    margin: auto !important;
    background-color: #FFFFFF;
    border-radius: 0px !important;
    box-shadow: none!important;
  }
  .container-secction-info-detail-event {
    margin-top: 10px !important;
  }

  .title-secction-detail-event {
    display: none;
  }
  .btn-register-event {
    display: none !important;
  }
  .qualification-comments-desktop{
    display: none;
  }
  .qualification-comments-mobile{
    display: flex;
  }
  .container-detail-event {
    margin-top: 30px;
    width: 100%;
    padding: 10px ;
  }
  .container-secction {
    width: 100%;
  }

  .container-footer-register-event{
    display: flex;
  }
  .container-btn-register-event{
    width: 50%;
    margin: auto !important;
  }
  .btn-register-phone-event{
    display: block;
    width: 100%;
  }
}

.text-area-msg-event-detail {
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  height: 200px;
  max-height: 200px;
}


@media screen and (max-width: 1200px){
  .grid-necesary-items{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 610px){
  .grid-necesary-items{
    grid-template-columns: repeat(1, 1fr);
  }
}