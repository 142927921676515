@import '../../variables';

body {
  background-color: #FFFFFF;
}

.row-bash {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.col-sm-bash {
  width: auto !important;
  flex-basis: 0;
  flex-grow: 0;
  max-width: 100%;
  position: relative;
  padding-right: 0;
  padding-left: 0;
}

.img-authentication {
  
  img {
    height: 100vh;
    width: auto;
  }
}


.vcenter-item{
  display: flex;
  align-items: center;
}

.input-login {
  width: 100%;
  margin: 1vh 0;
}

.container-input {
  margin-bottom: 3vh;
}

.btn-login-bash {
  width: 100%;
}

.text-login-social {
  padding: 2vh 0;
  color: #9EA0A5;
  text-align: center;
  margin: 0;
}

.text-welcome-register {
  color: #9EA0A5;
}

.welcome-bash {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.00938em;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.login-bash {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-bottom: 4vh;
}

.forgot-passsword {

  margin-top: 3vh;
  color: #9EA0A5;
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 16px;

  span {
    margin-left: 4px;
    color: #8245BF;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.05px;
    line-height: 16px;
  }
}

.grid-inputs {
  margin: 1vh 0;
  width: 100%;
  height: auto !important;
}
@media screen and (min-width: 576px){
  .col-sm {
    flex-grow: 0;
  }
}