@import '../variables';

.container-img {
  position: relative;
  text-align: center;
  color: white;

  img {
    max-width: 100%;
    max-height: 100%;
    display: block
  }
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.centered {
  position: absolute;
  margin: auto;
  width: 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-search {
  color: $white-color !important;
  font-size: 50px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 58px !important;
  text-shadow: 0 2px $text-color !important;
}

.description-search-events {
  display: grid !important;

  p {
    color: $white-color !important;
    font-size: 24px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
    text-align: center !important;
    text-shadow: 0 2px $text-color !important;
    margin-bottom: 2vh;
  }
}

.btn-more-events-bash {
  display: grid;
  width: 44%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 9vh;

  button {
    background-color: rgba(255,255,255,    0%);
    height: 54px;
    color: $green-color-btn;
    border: 1px solid $green-color-btn;
    border-radius: 21px;
    box-shadow: 0 10px 24px 0 rgba(0,0,0,0.19)
  }

  button:hover {
    background-color: $green-color-btn;
    color: $white-color;
    border: 1px solid $green-color-btn;
  }

  button:focus {
    background-color: $green-color-btn;
    color: $white-color;
    border: 1px solid $green-color-btn;
  }

  button:active {
    background-color: #029a70;
    color: $white-color;
    border: 1px solid #029a70;
  }
}

body {
}
body:after {
  content: ''; 
  
}

.container-loader-carousel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
}
@media screen and (max-width: 900px) {
  .title-search {
    font-size: 30px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    text-shadow: 0 0 29px 0 #383535 !important;
  }

  .btn-more-events-bash {
    width: 80%;
  }

  .description-search-events {
    display: none !important;
  }

  .centered {
    margin-top: 20px;
  }
  
}