.search-group-header {
  width: 100%;
}

.container-input-group {
  //background-color: #FFFFFF;
  //width: fit-content;
  width:70%;
  margin: auto !important;
  //border-radius: 12px;
  //color: #747779;
  //box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2) !important;
  border-radius: 21px !important;
}
.input-search-event-bash {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 22px;
  letter-spacing: -0.2px;
  line-height: 18px;
  text-align: left;
  color: #747779;
  background-color: #FFFFFF;
  margin-left: 0px;
  margin-right: 10px;
  padding: 30px 20px;
  border-radius: 12px;
  height: 36px;
  border: 0;
  width: 100%;
  border-radius: 21px !important;
}

.btn-submit-form-bash{
  width: 100%;
  height: 100%;
  border-radius: 21px !important;
}
.search-icon-bash {
  vertical-align: middle;
  display: none !important;
}

.container-form {
  margin-top: 3vh;
}

.container-selection-filter-events-bash{
  display: flex;
  width: 70%;
  margin: auto;
  overflow-y: hidden;
  overflow-x: auto;
  margin-top: 1vh;

  button {
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;
    border-radius: 20px;
    margin-right: 14px !important;

    span {
      p {
        font-size: 14px !important;
        color: #747779 !important;
        text-transform: capitalize;
      }
    }
  }
}

.item-filter-event-bash{
  background-color: #FFFFFF;
  margin-right: 1vw;
  width: fit-content;
  padding: 3px 30px;
  color: #747779;
  border-radius: 12px;
}

@media screen and (max-width: 959px) {
  .search-icon-bash {
    display: block !important;
    margin-top: 8px;
    margin-right: 8px !important;
  }
  .btn-submit-form-bash{
    display: none !important;
  }

  .input-search-event-bash {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 18px;
    text-align: left;
    color: #747779;
    background-color: #FFFFFF;
    margin-left: 0px;
    margin-right: 10px;
    padding: 20px 10px;
    border-radius: 12px !important;
    height: 36px;
    border: 0;
    width: 100%;
  }
  .container-input-group {
    border-radius: 12px !important;
  }
  .item-filter-event-bash{
    padding: 3px 10px;
    font-size: 14px;
  }
}

.scroll-horizontal-filters {
  display: flex;
  overflow-y: auto;
}
