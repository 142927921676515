.container-my-events{
  margin: 2vh 3vw;
}

.title-section-bash {
  height: 48px;
  width: 100px;
  color: #000000;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 48px;
}

.card-new-event {
  max-width: 215px;
  margin-left: 2vw;
  margin-bottom: 4vh;

  img {
    width: 50%;
    margin: 3vh 25% 2vh;
  }

  .text-create-event {
    margin-top: 3vh;
    color: #02D99D;
    font-weight: 600;
    letter-spacing: -0.27px;
    line-height: 20px;
    text-decoration: underline;
    margin-bottom: 10px;
  }
}

.nothin-event {
  text-align: center;
  margin: 3vh;
  color: #808285;

  p {
    font-size: 25px !important;
  }
  img {
    width: 100px !important;
    margin: 2vh !important;
  }
}

.container-image-delete-event {
  text-align: center !important;
}

.text-delete-event {
  text-align: center !important;
  color: black !important;
  margin-top: 4vh !important;
  font-size: 19px !important;
  font-weight: 500 !important;
}
.container-delete-event-btn {
  text-align: center;

  button {
    margin: 1vh 1vw;
  }
}
.cancel-delete-event-btn{
  background-color: white !important;
  
  
}