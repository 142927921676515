.parent-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);

  div {
    position: absolute;
    top: 45%;
    left: 45%;
  }
}