@import '../variables';

.root-authentication-bash {
  height: 100vh;
}
.img-authentication {
  background-image: url('../../img/img-autentication.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.container-mask-bash {
  position: relative;
  img.img-mask {
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
  }
}
.experience-bash {
  color: $white-color;
  font-size: 34px;
  font-weight: 300;
  letter-spacing: -0.12px;
  line-height: 40px;
  text-align: center;
  padding: 15vh 5%;
}

.img-bash {
  height: 65px;
  margin-left: 55px;
}

.container-form-authentication {
  margin-top: 40px;
  margin-left: 17px;
}

.container-login {
  margin-top: 20vh;
  width: 70%;
  padding: 0 10%;
}

.container-text-experiences-bash {
  margin-top: 15vh;
}
@media screen and (max-width: 960px) {
  .container-mask-bash {
    img {
      opacity: 0.5;
    }
  }
  .img-authentication {
    p {
      display: none;
    }
  }

  .experience-bash {
    display: none;
  }
  .img-bash {
    display: none;
  }
  
  .container-login {
    margin-top: 5vh;
    width: 80%;
    padding: 0 10%;
  }
}

.btn-back {
  height: 4rem !important;
}
@media screen and (max-width: 550px) {

  .container-login {
    margin-top: 5vh;
    width: 100%;
    padding: 0 10%;
  }
}