
@import '../variables';

.card-information {
  height: 216px;
  width: 200px;
  margin: auto;
}

.img-information {
  width: 100%;
  text-align: center;
  align-items: center;

  img{
    margin: auto;
    width: 60px;
    height: 60px;
  }
}

.name-information {
  width: 100%;
  text-align: center;
  align-items: center;
  margin-top: 15px;
  span {
    margin: auto;
    color: #6805F2;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 35px;
  }
}

.checked-information {
  margin-left: 10px;
}

@media screen and (max-width: 1050px) {
  .card-information {
    width: 130px;
    height: 130px;
  }

  .img-information {
    img{
      margin: auto;
      width: 40px;
      height: 40px;
    }
  }

  .name-information {
    span {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
}