.container-rigth-items {
  text-align: end;
}

.container-title-msg-send {
  background-color: #C8A0FC !important;
}
.title-msg-send {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
}

.text-area-send-message{

  width: 100% !important;
  resize: none;
  margin-top: 2vh;
  border-radius: 5px;
  padding: 1vh 3vw;
  font-family: 'Roboto';
}

.img-event-without-user{
  display: grid;
  text-align: center;

  img {
    max-width: 300px;
    width: 80% !important;
    margin: auto;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }
}