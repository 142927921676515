@import '../../variables';

.btn-facebook {
  width: 100% !important;
  border: 0 !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  line-height: 1.75 !important;
  border-radius: 5px !important;
  background-color: #1070CA !important;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2) !important;
  color: $white-color !important;
}

.btn-google {
  margin-top: 2vh !important;
  width: 100% !important;
  border: 0 !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  line-height: 1.75 !important;
  border-radius: 5px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2) !important;
  color: #234361 !important;
}

.btn-icon-with-text-center {
  display: flex;
  margin: auto;
  width: fit-content;

  img {
    margin-right: 10px;
    opacity: 1;
  }

  img.facebook {
    width: 9px;
  }

  img.google {
    width: 15px;
  }
}