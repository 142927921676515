.container-profile-bash {
  margin: 0 0 0 5rem;
}

.container-menu-profile-bash {
  border-right: 1px solid #979797;
}

.container-component {
  margin-bottom: 3vh;
}

.tab-user-bash {
  padding: 3vh 10px;
  transition: 0.3s;

  &:hover {
    background-color: #EADDFD;
  }
}

.tab-user-select-bash {
  background-color: #CEAFFB;
}

.container-children-profile {
  margin: 1rem 1rem;
}
@media screen and (max-width: 1050px) {
  .container-profile-bash {
    margin: 0 0 0 1rem;
  } 
}

@media screen and (max-width: 959px) {
  .container-profile-bash {
    margin: 0 1rem 0 1rem;
  }
  .container-menu-profile-bash {
    border-right: 0px;
  }

  .container-list-tabs-bash {
    display: flex;
  }

  .tab-user-bash {
    width: 50%;
    text-align: center;
    padding: 1vh 0;
    border-bottom: 3px solid #CEAFFB;
  }

}