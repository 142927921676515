@import '../variables';

.container-header {
  background-color: #FFFFFF;
  box-shadow: 0 10px 24px 0 rgba(0,0,0,0.1);
  padding: 1vh 1vw
}
.navbar {
  background-color: $background-color;
  height: 90px;
  padding: 0 5rem 0 5rem;
}

.header {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.header {
  a {
    float: left;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px; 
    line-height: 25px;
    border-radius: 4px;
    padding-top: 6px;

    &:hover {
      text-decoration: none;
    }
  }
}

.header a.logo {

  p {
    color: $text-color;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 47px;
    text-shadow: 0 0 29px 0 #FFFFFF;
    float: left;
  }
}

.header-right {
  float: right;
}

.close-btn-menu-mobile {
  position: absolute;
  right: 2rem;
  top: 0;

  svg {
    font-size: 40px;
  }
}
.menu-desktop {
  display: flex;
  font-family: 'Roboto-RG';
  font-size: 15px;
}

.app-search {
  display: table;
  margin-right: 1vw;
  margin-top: 15px;
  overflow: hidden;
}

.app-search-box {
  vertical-align: middle;
}

.close-btn-menu-mobile {
  color: $text-color;
  padding-top: 10px;
}
.menu-client {
  display: none;
}

.favorites-bash {
  color: $secondary-color;
  display: flex;
  svg {
    font-size: 30px;
  }

  p {
    margin-left: 0.4rem;
    text-align: center;
    padding-top: 3px;
  }
}

.user-dropdown {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  top: 100%;
  left: 15%;
  right: 0;
  width: 225px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.absolute {
  position: absolute;
  margin-top: 1rem;
}

.notifications-absolute {
  position: absolute;
  margin-top: 1.5rem;
  z-index: 1000;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.user-menu {
  margin-top: 12px;
  margin-right: 0;
}

.menu-icons {
  display: inline-flex;
  color: #D1D3D4;

  font-size: 20px;
  cursor: pointer;

  .arrow-icon {
    svg {
      margin-top: 0.5rem;
      margin-left: 0.3rem;
      height: 24px;
      width: 24px;
    }
  }
}

.img-user {
  width: 40px !important;
  height: 40px !important;
  border-radius: 30px;
}

.card-user-menu {
  border-radius: 1px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  padding: 1rem;
  font-family: 'Roboto-RG';
  color: $menu-text-color;
  font-size: 16px;
  z-index: 10000;

  .line-separate{
    padding-bottom: 0.2rem;
    border-bottom: 1px solid $menu-text-color;
  }

  .close-session {
    padding-top: 0.3rem;
  }
}

.container-notification-bash {
  height: 80px;
  width: 295px;
  padding: 10px 0;
  display: flex;
}

.text-dots-notification {
  overflow: hidden;
  width: 215px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.view-more-container {
  border-left: 1px solid #A9A4A4;
  position: relative;
}

.view-more {
  position: absolute;
  top: 30%;
  left: 10px;
}
.text-center {
  text-align: center;
}

.text-view-more {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.horario-notificacion-bash{
  color: #A9A4A4;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
}

.notifications-bash-container {
  display: inline-flex;
  color: $text-color;
  cursor: pointer;

  .icon-notifications-bash {
    margin-right: 10px;

    svg {
      font-size: 30px;
    }
  }

  span {
    margin-top: 0.4rem;
    margin-left: 0.3rem;
  }
}

.icon-menu-mobile {
  color: $text-color;
}

.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $white-color;
  overflow-y: auto;
  transition: 0.3s;

  .overlay-content {
    position: relative;
    width: 100%;
    text-align: center;

    .container-menu-mobile {
      color: #151F2F;
      font-family: 'Roboto-RG';
      font-size: 30px;
      letter-spacing: 0;
      line-height: 35px;
      text-shadow: 0 0 29px 0 #FFFFFF;
    }

    .formating-menu-mobile {
      padding: 2rem 0 2rem 0;
      margin: 0 3rem 0 3rem;
      border-bottom: 1px solid #F2EBEB;
    }
  }
}

.menu-mobile-text {

  p {
    color: $title-text-color;
    color: #5C5F64;
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;

    &:hover {
      color: $title-text-color;
      text-decoration: none;
    }
  }
}

.menu-containe-desktop{
  margin-top: 10px;
}

.text-header-bash {
  color: #5C5F64 !important;
  font-size: 20px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  margin-top: 6px !important;
}
@media screen and (max-width: 1050px) {

  .navbar {
    padding: 0 0 0 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .header a {
    float: none;
    display: block;
    text-align: center;
    font-size: 30px;
  }
  
  .menu-desktop {
    display: none;
  }

  .menu-client {
    display: block;
  }

  .app-search {
    margin-right: 2rem;
    margin-top: 0;
  }
}
