@import '../../variables';

.container-card-event {
  width: min-content;
  position: relative;
  padding-top: 100px;
}

.img-card-event {
  width: 100%;
  max-height: 150px;
  transform: translateY(20%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
}

.card-new-event-carousel {
  width: 100%;

  &:active {
    outline: 'none',
  }

  &:hover {
    outline: 'none',
  }
}

.container-location-card {
  margin-top: 2vh !important;
}
.row-card {
  display: flex;
}

.without-paddings {
  padding: 0 0 0 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.icon-marked-bash {
  margin-left: -5px;
  font-size: 15px;
  margin-top: 5px;
  color: #F86B6A;
}

.text-dots {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.text-card-event-bash {
  margin-top: 6px !important;
  color: #151F2F !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
}
.text-low-card-event-bash {
  margin-top: 10px !important;
  color: #5C5F64 !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 14px !important;
}

.star-qualification-bash {
  margin-left: 5px;
  margin-right: 5px;;
}

.btn-circle.btn-sm { 
  width: 30px; 
  height: 30px; 
  padding: 6px 0px; 
  border-radius: 15px; 
  font-size: 8px; 
  text-align: center; 
} 
.btn-circle.btn-md { 
  width: 50px; 
  height: 50px; 
  padding: 7px 10px; 
  border-radius: 25px; 
  font-size: 10px; 
  text-align: center; 
} 
.btn-circle.btn-xl { 
  width: 40px; 
  height: 40px; 
  padding: 10px 16px; 
  border-radius: 35px; 
  font-size: 12px; 
  text-align: center; 
  position: absolute;
  right: 25px;
  top: 130px;
} 

.text-margins-location {
  color: #5C5F64;
  padding-left: 4px;
  font-size: 18px;
  margin-top: 8px;
}

.container-qualification {
  display: flex;

  svg {
    margin-top: 4px !important;
  }
}

.btn-publish {
  width: fit-content;
}

.container-img-event-card{
  position: relative;
  display: flex;
  justify-content: center;
  height: 160px;
}
.container-actions-card {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
  margin-right: 15px;
  
  svg {
    background-color: white;
    border-radius: 14px;
    margin-bottom: -38px;
    margin-right: 10px;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    color: #808285;
    width: 27px;
    cursor: pointer;
  }
}
@media screen and (max-width: 960px) {

  .container-location-card {
    margin-top: 3vh !important;
  }

  .img-card-event {
    width: 100%;
    margin-left: 0;
  }
}