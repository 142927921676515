.container-loader-create-event {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}

.container-create-event {
  padding: 3vh 1vw;
}

.btn-back-page {
  cursor: pointer;
}

.icon-step {
  margin-top: 7px;
}

.mobile-steper {
  display: none;
}

.group-btns-steps {
  text-align: center;

  .back-btn-step {
    background-color: #ffffff !important;
    box-shadow: none !important;
    margin-right: 15px;

    &:focus {
      background-color: #ffffff !important;
      box-shadow: none !important;
    }
  }
}

.title-step {
  font-size: 42px !important;
  letter-spacing: 0 !important;
  line-height: 48px !important;
  text-align: center !important;
}

.sub-title-step {
  font-size: 24px !important;
  letter-spacing: 0 !important;
  line-height: 48px !important;
  text-align: center;
}

.sub-title-bash-step {
  font-size: 24px !important;
  letter-spacing: 0 !important;
  line-height: 48px !important;
  text-align: center !important;
}

.subtitile-step {
  font-size: 25px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #5c5f64;
  margin-top: 1vh;
}
.container-steps {
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding: 0 1vh;
}

.input-autocomplete {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0vh !important;
}

.section-input {
  padding: 4vh 20% !important;
}

.input-step {
  width: 60% !important;
  margin: 4vh 20% !important;
}

.btn-outline-bash {
  width: 90%;
  height: 100%;
  margin-left: 10% !important;
}

.btn-outline-bash-title {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.box-location-btns {
  margin: 3px;
}
.container-img-event {
  margin: 0;
  border-radius: 40px;
  border: 1px solid #5c5f64;
  padding: 4vh 1vw;
}

.mb-3 {
  margin-bottom: 3vh !important;
}
.mt-1 {
  margin-top: 1vh !important;
}
.mt-2 {
  margin-top: 2vh !important;
}
.mt-3 {
  margin-top: 3vh !important;
}
.mt-4 {
  margin-top: 4vh !important;
}
.mt-5 {
  margin-top: 5vh !important;
}

.container-description{
  padding-left: 0vw !important;
}

.text-area-description {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  height: 100% !important;
  border-radius: 15px;
  border: 1px solid #5c5f64;
  resize: vertical;
  min-height: 200px;
  padding: 10px;
}

.container-date {
  width: 90% !important;
  margin: 0 5% !important;
}

.container-left {
  margin-left: 5%;
}

.select-event-categories-text {
  color: #02D99D !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  letter-spacing: -0.27px !important;
  line-height: 20px !important;
  text-decoration: underline;
  cursor: pointer;
}

.share-social-event{
  color: #5c5f64 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: -0.27px !important;
  line-height: 20px !important;
}

.container-list-social {
  display: flex;
  margin-top: 1vh;
  margin-bottom: 2vh;
}
.container-icon-social {
  width: 50px;
  text-align: center;
  height: 50px;
  background-color: #c6c9ce;
  display: block;
  border-radius: 40px;
  margin-right: 5px;
  cursor: pointer;
}

.container-icon-social.active {
  background-color: #5c5f64;
}

.icon-social-share {
  margin-top: 11px !important;
  font-size: 28px !important;
  color: #5c5f64;
}

.icon-social-share.active {
  color: #02D99D
}

.text-center {
  text-align: center;
}

.container-currency-select {
  margin-right: 2vh !important;
}

.label-items-selected {
  color: #292929;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 18px;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .desktop-steper {
    display: none;
  }
  .mobile-steper {
    display: inline;
  }
  .container-create-event {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .container-steps {
    padding: 0 3vh;
  }
  .mobile-back-btn {
    padding-top: 5vh;
  }

  .title-step {
    font-size: 16px !important;
    line-height: 19px !important;
    margin-bottom: 3vh !important;
    text-align: left !important;
  }

  .sub-title-step {
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 19px !important;
    margin-bottom: 3vh !important;
    text-align: left !important;
  }

  .sub-title-bash-step {
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 19px !important;
    margin-bottom: 3vh !important;
    text-align: center !important;
  }

  .input-step {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 3vh !important;
  }

  .section-input {
    padding: 4vh 0 !important;
  }
  
  .btn-outline-bash {
    width: 100% !important;
    height: 65% !important;
    margin-left: 0 !important;
    margin-top: 2vh !important;
  }

  .subtitile-step {
    font-size: 15px !important;
    color: #5c5f64;
  }
  .container-img-event {
    margin: 0;
    border-radius: 20px;
    padding: 4vh 1vw;
  }
  .container-description{
    padding-left: 0vw !important;
  }
  .container-date {
    margin: 2vh 5% !important;
  }

  .btn-create-event {
    width: 100%;
  }
}

.title-select-categories {
  text-align: center;
}

.container-selected-categories {
  padding: 1vh 1vw;
}

.content-name-step {
  padding: 8px;
  color: #151516;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.23px;
  line-height: 18px;
}

.img-categorie {
  width: 100%;
  text-align: center;
  align-items: center;

  img{
    margin: auto;
    width: 40px;
    height: 40px;
  }
}

.name-categorie {
  width: 100%;
  text-align: center;
  align-items: center;
  margin-top: 15px;

  span {
    margin: auto;
    color: #6805F2;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }
}

.item-categorie-new-event {
  max-width: 146px;
  margin: auto;
}

.category-selected {
  width: fit-content;
  padding: 3px 8px;
  border-radius: 15px;
  border: 1px solid #5c5f64;
  display: flex;
  font-size: 13px;
  color: #5c5f64;
  float: left;
  margin-left: 1px;
  margin-top: 4px;

  .container-title-category {
    width: fit-content;
  }

  svg {
    font-size: 18px;
    margin-left: 4px;
    margin-top: 4px;
    cursor: pointer;
  }
}

.container-categories-selected {
  display: flow-root;
}