$background-color: #FFFFFF;

$secondary-color : #6805F2;

$white-color: #FFFFFF;

$title-text-color: #050505;

$text-color: #454648;

$sub-title: #A9A4A4;

$menu-text-color: #979797;

$green-color-btn: #02D99D;

$max-width-tablet: 1050px;