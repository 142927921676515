@import "variables";

body {
  margin: 0;
  font-family: Roboto-RG, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: $background-color;
  overflow-x: hidden;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.pb-3 {
  padding-bottom: 3rem;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.create-event-btn {
  height: 36px;
  width: 151px;
  border-radius: 5px;
  border: 0px;
  background-color: $secondary-color;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.19);

  color: $background-color;

  &:hover {
    background-color: $secondary-color;
  }

  div {
    padding: 5px 5px 5px 5px;
    span {
      padding-left: 10px;
      height: 16px;
      width: 79px;
      color: $white-color;
      font-family: Roboto;
      font-size: 1em;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.btn:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.text-header-page-url {
  color: #808285;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.text-title-page-url {
  color: #000000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 48px;
}

.container-header-page-url {
  margin: 5vh 5rem;
  width: auto !important;
}

.color-ligth-text-bash {
  color: #6E7173;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 48px;
}

.text-alig-center {
  text-align: center !important;
}

.drop-zone-img-bash {
  text-align: center;
  img {
    width: 30%;
  }
  span {
    color: #d1d3d4;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 48px;
  }

  .action-upload-img {
    span {
      color: #02d99d;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.27px;
      line-height: 20px;
      text-align: center;
      border-bottom: 1px solid #02d99d;
      cursor: pointer;
    }
  }
}

.container-message-success {
  background-color: #01af64;
  color: white;
  min-height: 70px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.container-message-text-success {
  margin: auto !important;

  p {
    padding-left: 2vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
}

.message-text-success {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.mask-change-password {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;

  .card-change-password {
    background-color: white;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    width: 585px;

    .container-form-change-password {
      width: 80%;
      margin: auto !important;

      .container-title-text {
        margin-top: 5vh !important;
        margin-bottom: 1vh !important;

        span.title-text {
          color: #000000;
          font-size: 32px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 30px;
          text-align: center;
        }
      }

      form {
        margin-bottom: 5vh;

        .input-change-password {
          margin-bottom: 3vh;
        }
      }
    }
  }
}

hr {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.btn-selected {
  opacity: 0.5 !important;
  background-color: rgba(123,79,255,0.32) !important;
}

.sub-title-bold {
  color: #090909 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: -0.23px !important;
  line-height: 18px !important;
}

.align-left {
  text-align: left !important;
}

.date-picker-grid {
  width: 98%;
}

.title-filter {
  color: #292929 !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  letter-spacing: -0.3px !important;
  line-height: 24px !important;
}

.subtitle-filter {
  color: #292929 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  letter-spacing: -0.3px !important;
  line-height: 24px !important;
}

.container-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}

.remind-password-section {
  text-align: end;
}

.remind-password {
  cursor: pointer;
  color: #1070CA;
}

@media screen and (max-width: 1050px) {
  .mobile-disable {
    display: none;
  }
  .container-header-page-url {
    margin: 5vh 1rem;
  }
}

@media screen and (max-width: 900px) {
  .text-alig-center {
    text-align: center !important;
  }
  .mask-change-password {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;

    .card-change-password {
      background-color: white;
      border-radius: 0px;
      top: 0;
      left: 0;
      position: absolute;
      transform: translateX(0) translateY(0);
      width: 100vw;
      height: 100%;

      .container-form-change-password {
        width: 100%;

        .container-title-text {
          width: 80%;
          margin: auto;
          margin-top: 5vh !important;
          margin-bottom: 1vh !important;
        }

        form {
          width: 80%;
          margin: auto;
          margin-bottom: 5vh;

          .input-change-password {
            margin-bottom: 3vh;
          }
        }
      }
    }
  }
}
