.container-text-link {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-top: 1vh;
  p {
    span {
      border-bottom: 1px solid #02D99D;
      color: #02D99D;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.27px;
      line-height: 20px;
      cursor: pointer;
    }
  }
}

.container-input-profile {
  text-align: center;
  margin: 4vh 0;
}

.input-form-profile {
  width: 80%;
}

.container-text-link-profile-bash {
  width: 80%;
  margin: auto;
  text-align: left;
  margin-top: 1vh;
  p {
    span {
      border-bottom: 1px solid #02D99D;
      color: #02D99D;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.27px;
      line-height: 20px;
      cursor: pointer;
    }
  }
}

.container-form-profile {
  width: 100%;
  
}

.title-interest-list {
  color: #000000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 48px;
}

.title-interest{
  color: #A7A9AC;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.container-interest-list{
  width: 90%;
  margin: auto;
  margin-top: 4vh;

  .container-list {
    margin-top: 2vh;
    display: flow-root;
    overflow-x: auto;
    padding-bottom: 15px;

    .item-list{
      margin-right: 5px;
      border: 1px solid #A7A9AC;
      padding: 2px 10px;
      border-radius: 20px;
      display: flex;
      width: fit-content;
      float: left;
      margin-top: 7px;

      svg {
        margin-top: 2px;
        cursor: pointer;
      }
    }
  }
}

.container-save-profile-btn {
  width: 40%;
  margin-left: 5%;
  margin-top: 5vh;
}
@media screen and (max-width: 960px) {
  .container-interest-list{
    width: 80%;
  }

  .container-save-profile-btn {
    width: 80%;
    margin-left: 10%;
  }
}