@import '../variables';

.container-mail-modal-bash {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  transition: visibility 0s, opacity 0.5s ease-in-out;
}

.modal-mobile {
  width: 400px;
  height: 315px;
  border-radius: 5px;
  background-color: #FFFFFF;
  display: block;
  margin: auto;
  margin-top: 30vh;
  transition: visibility 0s, opacity 0.5s ease-in-out;
}

.container-message-mail-bash {
  align-items: center;
  text-align: center;
  padding: 10% 10%;
  transition: visibility 0s, opacity 0.5s ease-in-out;
}

.icon-mail {
  color: #454648;
  margin-bottom: 16px;
}

.view-mail {
  color: #292929;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.45px;
  line-height: 26px;
}

.container-message-bash {
  margin-top: 16px;
  margin-bottom: 50px;
}

.btn-confirm-email {
  width: 100%;
}

@media screen and (max-width: 700px){
  .modal-mobile {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  .container-mail-modal-bash {
    top: 0;
  }
}