@import '../variables';

.more-information-bash {
  max-width: 886px;
  text-align: center;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  align-items: center;

  .title-more-information {
    color: #000000;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
    margin-top: 4vh;
    padding: 0 1vw;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  padding: 10vh 10vw;
  align-items: center;
}

.container-button-add-items {
  max-width: 420px;
  margin: auto ;
  margin-top: 40px;
  margin-bottom: 40px;

  .btn-items-selected-bash {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .more-information-bash {
  
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    .title-more-information {
      font-size: 20px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: 1550px) {
  .wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1300px) {
  .wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1050px) {
  .wrapper {
    grid-template-columns: repeat(6, 1fr);
    padding: 2vh 2vw;
    align-items: center;

  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 700px) {
  .wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .container-button-add-items{
    margin: 40px 3vw;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}