@import '../../variables';

.title-section-event {
  display: inline-grid;
  display: -moz-inline-grid;
  display: -ms-inline-grid;
  width: 100vw;
  text-align: center;
  margin-top: 50px;

  span {
    color: $title-text-color;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
  }

  img {
    padding-top: 18px;
    margin: auto;
  }
}
.content {
  padding: 0 5rem 0 5rem;
  width: 100%;
}

.content-carousel-detail-event{
  padding: 0;
}

.items-carrousel {
  padding-left: 10px;
}

.carousel-container {
  width: 100%;
  padding-bottom: 35px;

  ul {
    li {
      padding: 1vh 1vw;
    }
  }
}

.arrow-right-custom {
  right: 1px;
}

.arrow-left-custom {
  left: 1px;
  transform: scaleX(-1);
  transform-origin: center;
  transform-box: fill-box;
}

.arrow-custom {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: $secondary-color;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.arrow-custom:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@media screen and (max-width: 1050px) {
  .content {
    padding: 0 0.5rem 0 0.5rem;
    width: 100%;
  }
}

@media screen and (max-width: 700px){
  .title-section-event {
    margin-top: 20px;
    span {
      font-size: 16px;
      margin: 0 1.5rem 0 1.5rem;
    }
  }
}