@import '../variables';

.nav-footer-bash {
  border-radius: 5px;
  background: linear-gradient(90deg, #6805F2 0%, #E9C46B 100%);
  box-shadow: 0 10px 24px 0 rgba(0,0,0,0.19);
  border-radius: 0;
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  color: $white-color;
}

.container-footer {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  width: 100%;
  display: inline-flex;
  padding: 3rem 0 7rem 0;

  .title-bash-footer {
    font-size: 50px !important;
    letter-spacing: 0 !important;
    line-height: 58px !important;
  }

  .title-seccions-footer {
    font-size: 28px !important;
    letter-spacing: 0 !important;
    line-height: 48px !important;
    margin-top: 1rem !important;
  }

  .list-text-seccion {
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 20px !important;
  }
}

.social-icons-bash {
  font-size: 20px;

  svg {
    margin-right: 2rem;
  }
}

.copyrigth-bash {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.img-map-container {
  background-image: url(../../img/map-footer.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.container-sections-footer {
  padding: 0vh 2vw;
}
@media screen and (max-width: 1050px) {

  .nav-footer-bash {
    background: linear-gradient(90deg, #6805F2 0%, #c18459 100%);
    box-shadow: 0 10px 24px 0 rgba(0,0,0,0.19);
  }

  .container-footer {

    display: inline;
    padding-left: 1vw;
    align-items: center;
    text-align: left;
    padding-left: 3vh;

    .seccion-mobile {
      padding: 0 0 0 0;
      width: 100%;
    }

    .title-bash-footer {
      font-size: 25px;
    }
  
    .title-seccions-footer {
      font-size: 20px;
    }
  }
}

.container-social-icons {
  svg {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1280px ) {
  .container-blank-space {
    display: none;
  }
}

@media screen and (max-width: 959px ) {
  .container-blank-space {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}